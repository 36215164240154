import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
} from "react";

import { gql } from "../../__generated__";
import {
  GetContentQuery,
  GetHeadingQuery,
  GetParagraphsQuery,
} from "../../__generated__/graphql";

export const GET_CONTENT_QUERY = gql(`
    query GetContent($hostname: String!, $languageCode: String) {
        countries {
            code
            localizedName: name(languageCode: $languageCode)
            name
            locality
            region
            emergencyNumber
            subdivisions {
                code
                localizedName: name(languageCode: $languageCode)
                name
            }
        }
        topics {
            name
            slug
            markdown(languageCode: $languageCode)
        }
        host(hostname: $hostname) {
            id
            name
            palettePrimaryMain
            palettePrimaryContrastText
            paletteSecondaryMain
            paletteSecondaryDark
            paletteSecondaryContrastText
            paletteTextPrimary
            paletteTextSecondary
            paletteBackgroundDefault
            paletteBackgroundPaper
            paletteBackgroundBanner
            paletteSuccessMain
            paletteSuccessContrastText
            paletteErrorMain
            paletteErrorContrastText
            paletteLanguageMain
            paletteLanguageContrastText
            paletteMenuMain
            paletteMenuContrastText
            defaultFontFamily
            headingFontFamily
            enableBannerBoxShadow
            overridesMuiAlertStandardInfoBackgroundColor
            overridesMuiAlertStandardInfoColor
            overridesMuiAlertStandardInfoIconColor
            overridesMuiButtonContainedBackgroundColor
            overridesMuiButtonContainedPrimaryBackground
            overridesMuiButtonContainedPrimaryColor
            overridesMuiButtonContainedSecondaryBackground
            overridesMuiButtonContainedSecondaryColor
            overridesMuiButtonFabPrimaryBackground
            overridesMuiButtonFabPrimaryColor
            overridesMuiButtonFabSecondaryBackground
            overridesMuiButtonFabSecondaryColor
            overridesMuiChipRootBackgroundColor
            overridesMuiChipRootColor
            overridesMuiOutlinedBackgroundColor
            overridesMuiOutlinedInputBorder
            overridesMuiOutlinedInputFocusedBorderColor
            overridesMuiRatingIconEmptyColor
            overridesMuiRatingIconFilledColor
            overridesMuiSwitchCheckedColor
            overridesMuiSwitchUncheckedColor
            overridesMuiTabRootBorderBottomColor
            overridesMuiTabsIndicatorBackgroundColor
            overridesMuiTooltipArrowColor
            overridesMuiTooltipTooltipBackgroundColor
            overridesOrganizationCardBorder
            allowAnalytics
            widgetBrandingVisible
            widgetBackdropVisible
            widgetEmbedLink
            pageAbout
            pageFaqs
            pageTermsOfService
            pagePrivacyPolicy
            pageGetTheWidget
            pageContact
            pageSitemap
            homePageUrl
            noResultsMarkdown
            noResultsWithFeaturedMarkdown
            logo {
                url
                width
                height
            }
            logoMaxWidth
            partnerLogo {
                url
                width
                height
            }
            partnerLogoMaxWidth
            favicon {
                url
                width
                height
            }
            footerByline
        }
    }
`);

export const GET_HEADING_QUERY = gql(`
    query GetHeading($languageCode: String!, $topicSlug: String, $countryCode: String, $subdivisionCode: String) {
        heading(
            languageCode: $languageCode
            topicSlug: $topicSlug
            countryCode: $countryCode
            subdivisionCode: $subdivisionCode
        ) {
            title
            heading
        }
    }
`);

export const GET_PARAGRAPHS_QUERY = gql(`
    query GetParagraphs($languageCode: String!, $seed: String!, $topicSlug: String!) {
        paragraphs(languageCode: $languageCode, seed: $seed, topicSlug: $topicSlug) {
            markdown
        }
    }
`);

const ALTERNATE_UK_COUNTRY_CODES = ["GB-ENG", "GB-SCT", "GB-WLS", "GB-NIR"];
const UNITED_KINGDOM_CODE = "GB";

export interface Content extends GetContentQuery {
  country?: GetContentQuery["countries"][0];
  subdivision?: GetContentQuery["countries"][0]["subdivisions"][0];
  alternativeCountries?: GetContentQuery["countries"];
  topic?: GetContentQuery["topics"][0];
  heading?: GetHeadingQuery["heading"];
  paragraphs?: GetParagraphsQuery["paragraphs"];
}

const ContentContext = createContext<Content | undefined>(undefined);

interface ContentProviderProps extends Partial<Content> {
  children: ReactNode;
}

const ContentProvider = ({
  children,
  countries = [],
  topics = [],
  country,
  alternativeCountries,
  subdivision,
  topic,
  host = null,
  heading,
  paragraphs = [],
}: ContentProviderProps): ReactElement => {
  return (
    <ContentContext.Provider
      value={{
        countries,
        topics,
        country,
        subdivision,
        alternativeCountries,
        topic,
        host,
        heading,
        paragraphs,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export function useContent(): Content {
  const context = useContext(ContentContext);

  return (
    context ?? {
      countries: [],
      topics: [],
      host: null,
      heading: null,
      paragraphs: [],
      alternativeCountries: null,
    }
  );
}

export async function getContent({
  hostname,
  countryCode,
  subdivisionCode,
  topicSlug,
  languageCode,
  client,
}: {
  hostname: string;
  countryCode?: string;
  subdivisionCode?: string;
  topicSlug?: string;
  languageCode?: string;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<Content> {
  const { data: content } = await client.query({
    query: GET_CONTENT_QUERY,
    variables: {
      languageCode: languageCode ?? "en",
      hostname,
    },
  });

  let heading: Content["heading"];
  let paragraphs: Content["paragraphs"];
  let alternativeCountries: Content["alternativeCountries"];
  if (countryCode || subdivisionCode || topicSlug) {
    const { data } = await client.query({
      query: GET_HEADING_QUERY,
      variables: {
        languageCode: languageCode ?? "en",
        topicSlug,
        countryCode,
        subdivisionCode,
      },
    });
    heading = data?.heading;
  }

  if (countryCode) {
    const { data } = await client.query({
      query: GET_PARAGRAPHS_QUERY,
      variables: {
        languageCode: languageCode ?? "en",
        seed:
          subdivisionCode ? `${subdivisionCode}, ${countryCode}` : countryCode,
        topicSlug: topicSlug ? topicSlug : "",
      },
    });
    paragraphs = data?.paragraphs;

    // Load the alternative countries for the UK, and UK consitituent countries. These are
    // used to prompt users with alternative search routes.
    alternativeCountries =
      countryCode.toUpperCase() === UNITED_KINGDOM_CODE ?
        content.countries.filter(({ code }) =>
          ALTERNATE_UK_COUNTRY_CODES.includes(code.toUpperCase()),
        )
      : (
        ALTERNATE_UK_COUNTRY_CODES.find((c) => c === countryCode.toUpperCase())
      ) ?
        [
          content.countries.find(
            ({ code }) => code.toUpperCase() === UNITED_KINGDOM_CODE,
          ),
        ]
      : undefined;
  }

  const country =
    countryCode != null ?
      content.countries.find(
        ({ code }) => code.toLowerCase() === countryCode.toLowerCase(),
      )
    : undefined;
  const subdivision =
    subdivisionCode != null ?
      country?.subdivisions?.find(
        ({ code }) => code.toLowerCase() === subdivisionCode.toLowerCase(),
      )
    : undefined;
  const topic =
    topicSlug != null ?
      content.topics.find(
        ({ slug }) => slug.toLowerCase() === topicSlug.toLowerCase(),
      )
    : undefined;
  const obj = {
    ...content,
    heading,
    country,
    subdivision,
    alternativeCountries,
    topic,
    paragraphs,
  };
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

  return obj;
}

export default ContentProvider;
